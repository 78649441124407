.targetSvgBox {
    /*display: inline-block;*/
    /*margin: 5px;*/
    /*margin-top: 100px;*/
    /*display: flex;*/
    /*flex-direction: inherit;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*border: 0px solid darkgreen;*/
    box-shadow: rgba(0,0,0,0.8) 0 0 10px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

.targetSvgBox:active {
    /*display: inline-block;*/
    /*margin: 5px;*/
    /*display: flex;*/
    /*flex-direction: inherit;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*border: 0px solid darkgreen;*/
    box-shadow: rgba(200,200,200,0.8) 0 0 15px;
    border-radius: 15px;
    /*background-color: #9BB7C9;*/
    background-color: #424242;
    /*color: #282c34;*/
    height: 100%;
    width: 100%;
}

.targetSvg {
    /*filter: drop-shadow( 16px 16px 2px rgba(28,24,32,0.7));*/
    /*filter: drop-shadow(16px 16px 2px red);*/
    height: 100%;
    width: 100%;
}

.targetSvgBodyAlive {
    fill: #8d973d;
    /*filter: drop-shadow( 3px 3px 2px rgba(11,255,255,0.7));*/
}

.targetSvgFaceAlive {
    fill: #fef100;
}

.targetSvgBodyHidden {
    fill: #979797;
    opacity: 0.5;
    /*filter: drop-shadow( 3px 3px 2px rgba(11,255,255,0.7));*/
}

.targetSvgFaceHidden {
    opacity: 0.5;
    fill: #F5F5F5;
}

.targetSvgBodyDead {
    fill: #F10C1A;
    /*filter: drop-shadow( 3px 3px 2px rgba(11,255,255,0.7));*/
}

.targetSvgFaceDead {
    fill: #23244D;
}

.targetAnimation {
    /*animation-duration: 280ms;*/
    /*animation-timing-function: ease-in-out;*/
    /*animation-fill-mode: forwards;*/
    /*animation-direction: alternate;*/
    /*display: block;*/
    /*max-width:230px;*/
    /*min-height:140px;*/
    /*!*width: auto;*!*/
    /*height: 100%;*/
    /*height: 190px;*/
    /*width: 100px;*/
    /*width: auto;*/
    /*max-width: 250px;*/
    /*min-width: 150px;*/
    /*min-width: 10px;*/
    /*background: #ffffff;*/
    border-radius: 12px;
    display: inline-block;
    /*margin: 10px 20px 10px 20px;*/
    transition: 0.6s ease-out;
    /*padding: 10px;*/
    padding-bottom: 10px;
    height: 100%;
    width: 100%;
}

.target-animate-out {
    /*animation-name: animate-out;*/
    opacity: 0.8;
    transform: perspective(150px) rotateX(65deg) scale(0.75);
    /*transform: rotateX(65deg);*/
    /*transform: perspective(50px) rotateX(45deg);*/
    /*perspective-origin: 100%;*/
    /*transform-origin: bottom;*/
    /*border: 1px solid darkgreen;*/
}

.target-animate-in {
    /*animation-name: animate-in;*/
    /*transform-origin: center;*/
    /*border: 1px solid darkgreen;*/
}

.target-text-header {
    /*position: absolute;*/
    z-index: 9999;
    /*margin-top: -30px;*/
    /*margin-left: 5px;*/
    /*bottom: revert;*/
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
    font-size: small;
    margin-top: 2px;
    /*height: 100%;*/
    width: 100%;
}