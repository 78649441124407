/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App {
  background-color: #282c34;
    /*min-height: 100%;*/
  /*min-height: calc(100vh - 120px);*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  padding: 5px;
}

.ControllButtons {
  background-color: #282c34;
  /*min-height: 100%;*/
  /*min-height: calc(100vh - 120px);*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  padding: 10px;
}

.ControllButton {
  padding: 10px;
}

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.BorderBox {
  box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  padding: 10px;
  /*margin-left: 20px;*/
  /*margin-right: 2px;*/
}
